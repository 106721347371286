import { BaseLoginProvider, SocialUser } from '@abacritt/angularx-social-login';
import { Injectable } from '@angular/core';

import { AppleLoginConfigInterface } from '../definitions/apple-login-config.interface';
import { ApiService } from '../services/api.service';
import { SubdomainConfigService } from '../services/subdomain-config.service';

// eslint-disable-next-line @typescript-eslint/naming-convention
declare let AppleID: any;

@Injectable({ providedIn: 'root' })
export class CustomAppleLoginProvider extends BaseLoginProvider {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public static readonly PROVIDER_ID = 'APPLE' as const;
  private appleLoginConfig: AppleLoginConfigInterface;

  constructor(
    private apiService: ApiService,
    private subDomainConfService: SubdomainConfigService
  ) {
    super();
    const socialLoginConfig = subDomainConfService.getConfigForSubdomain(apiService.getApiHostname()).socialLoginConfig;
    this.appleLoginConfig = {
      clientId: socialLoginConfig.apple.clientId,
      scope: socialLoginConfig.apple.scope,
      redirectURI: window.location.origin,
      state: 'custom-state',
      usePopup: true,
      nonce: 'euroinnova'
    };
  }

  getLoginStatus(): Promise<SocialUser> {
    return new Promise((resolve) => {
      resolve({} as SocialUser);
    });
  }

  initialize(): Promise<void> {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
      script.onload = () => {
        AppleID.auth.init(this.appleLoginConfig);
        resolve();
      };
      script.onerror = reject;
      document.body.appendChild(script);
    });
  }

  signIn(_signInOptions?: Record<string, string>): Promise<any> {
    return new Promise((resolve, reject) => {
      AppleID.auth
        .signIn()
        .then((response: any) => {
          resolve({
            authToken: response.authorization.id_token,
            code: response.authorization.code
          });
        })
        .catch(reject);
    });
  }

  signOut(): Promise<void> {
    return new Promise((resolve) => {
      resolve(); // Apple does not provide a logout method
    });
  }
}
