import { BaseLoginProvider, SocialUser } from '@abacritt/angularx-social-login';
import { HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { ApiService } from '../services/api.service';
import { DomainConfigService } from '../services/domain-config.service';
import { manageOauth2Flow } from '../utils/shared-functions';

@Injectable({ providedIn: 'root' })
export class EuroinnovaLoginProvider extends BaseLoginProvider {
  private apiService = inject(ApiService);
  private domainConfService = inject(DomainConfigService);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  public static readonly PROVIDER_ID = 'EUROINNOVA' as const;

  /**
   * Base authorization Url
   */
  baseAuthUrl: string;

  constructor() {
    super();

    this.baseAuthUrl = this.domainConfService.getConfigForSubdomain(
      this.apiService.getApiHostname()
    ).socialLoginConfig.euroinnova.baseUrl;
  }

  initialize(): Promise<void> {
    return Promise.resolve();
  }

  getLoginStatus(): Promise<SocialUser> {
    return new Promise((resolve) => {
      resolve({} as SocialUser);
    });
  }

  signIn(signInOptions: Record<string, string>): Promise<any> {
    if (signInOptions === undefined) {
      return Promise.reject();
    } else {
      const oauthUrl =
        this.baseAuthUrl +
        '/?' +
        new HttpParams({
          fromObject: {
            ...signInOptions
          }
        }).toString();

      return manageOauth2Flow(oauthUrl);
    }
  }

  signOut(): Promise<void> {
    return Promise.resolve();
  }
}
