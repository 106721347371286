import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';

import { GeneralDialogComponent } from '../app/shared-components/general-dialog/general-dialog.component';
import { DialogInterface } from '../definitions/dialog.interface';
import { currencyMapping } from '../models/course';
import { INTERVAL_DELAY } from './constants';

/**
 * This method compares the element height and the truncated height to detect
 * if the 'text-overflow: ellipsis;' property is active.
 * @param e: HTMLElement - The element we want to know if has been truncated or not
 *
 * Inspired on this: https://stackoverflow.com/questions/7738117/html-text-overflow-ellipsis-detection
 */

export const isEllipsisActive = (e: HTMLElement | any): boolean => {
  return e?.offsetHeight < e?.scrollHeight;
};

export function handle404Error(err: HttpErrorResponse, router: Router): Observable<never> {
  if (err.status === 404) {
    router.navigateByUrl('/404');
  }

  return throwError(() => err);
}

export function parseUtcLocalTimeZone(utcDate: Date): Date {
  const offset = utcDate.getTimezoneOffset();
  const localDate = new Date(utcDate.getTime() - offset * 60 * 1000);

  return localDate;
}

export function manageOauth2Flow(url: string): Promise<any> {
  return new Promise((resolve, reject) => {
    const windowHandle = createOauthWindow(url);

    if (windowHandle !== null) {
      let loopCount = 600;
      const interval = setInterval(() => {
        if (loopCount-- < 0 || windowHandle.closed) {
          window.clearInterval(interval);
          windowHandle.close();
          reject();
        } else {
          let href: string;
          // For referencing window url
          try {
            href = windowHandle.location.href;
          } catch (e) {
            return;
          }
          if (href != null) {
            // Method for getting query parameters from query string
            const getQueryString = function (field: any, url: string) {
              const windowLocationUrl = url ? url : href;
              const reg = new RegExp('[?&]' + field + '=([^&#]*)', 'i');
              const string = reg.exec(windowLocationUrl);

              return string ? string[1] : null;
            };

            if (href.match('code')) {
              // for google , fb, github, linkedin
              window.clearInterval(interval);
              const authorizationCode = getQueryString('code', href);

              resolve({ authorizationCode });
              windowHandle.close();
            }
          }
        }
      }, INTERVAL_DELAY);
    }
  });
}

function createOauthWindow(
  url: string,
  name = 'Authorization',
  width = 500,
  height = 600,
  left = 0,
  top = 0
): Window | null {
  if (url == null) {
    return null;
  }
  const options = `width=${width},height=${height},left=${left},top=${top}`;

  return window.open(url, name, options);
}

export function openAnnouncementModal(dialog: MatDialog): void {
  const modalParams: MatDialogConfig<DialogInterface> = {
    width: '36.5rem',
    height: 'auto',
    data: {
      title: 'announcement.title',
      text: 'announcement.text'
    }
  };
  setTimeout(() => dialog.open(GeneralDialogComponent, modalParams), 1000);
}

export function getPrice(price: number, countryCode: string | null = 'ES'): string {
  if (!countryCode) {
    return price.toFixed(2) + currencyMapping['ES'] || '€';
  }

  return price.toFixed(2) + currencyMapping[countryCode] || '€';
}
