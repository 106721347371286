import { BaseLoginProvider, SocialUser } from '@abacritt/angularx-social-login';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { manageOauth2Flow } from '../utils/shared-functions';

@Injectable({ providedIn: 'root' })
export class LinkedinLoginProvider extends BaseLoginProvider {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public static readonly PROVIDER_ID = 'LINKEDIN' as const;

  initialize(): Promise<void> {
    return Promise.resolve();
  }

  getLoginStatus(): Promise<SocialUser> {
    return new Promise((resolve) => {
      resolve({} as SocialUser);
    });
  }

  signIn(signInOptions: Record<string, string>): Promise<any> {
    if (signInOptions === undefined) {
      return Promise.reject();
    } else {
      const oauthUrl =
        'https://www.linkedin.com/oauth/v2/authorization' +
        '?' +
        new HttpParams({
          fromObject: {
            ...signInOptions
          }
        }).toString();

      return manageOauth2Flow(oauthUrl);
    }
  }

  signOut(): Promise<void> {
    return Promise.resolve();
  }
}
