import { Injectable } from '@angular/core';

import { CompanyEnum } from '../definitions/company.enum';
import {
  ceupeConf,
  CustomerConfInterface,
  educaConf,
  educaopenConf,
  edusportConf,
  enaldeConf,
  esibeConf,
  euroinnovaConf,
  ineafConf,
  inesaludConf,
  inesemConf,
  innovaConf,
  rededucaConf,
  structuraliaConf,
  udavinciConf,
  universityConf
} from '../environments/customer.conf';

@Injectable({
  providedIn: 'root'
})
export class SubdomainConfigService {
  public getConfigForSubdomain(subdomain: string): CustomerConfInterface {
    switch (subdomain) {
      case CompanyEnum.INESALUD:
        return inesaludConf;
      case CompanyEnum.INESEM:
        return inesemConf;
      case CompanyEnum.EDUCA:
        return educaConf;
      case CompanyEnum.EDUSPORT:
        return edusportConf;
      case CompanyEnum.ENALDE:
        return enaldeConf;
      case CompanyEnum.ESIBE:
        return esibeConf;
      case CompanyEnum.INEAF:
        return ineafConf;
      case CompanyEnum.INNOVA:
        return innovaConf;
      case CompanyEnum.REDEDUCA:
        return rededucaConf;
      case CompanyEnum.EDUCAOPEN:
        return educaopenConf;
      case CompanyEnum.EUROINNOVA:
        return euroinnovaConf;
      case CompanyEnum.EUROINNOVALEGACY:
        return euroinnovaConf;
      case CompanyEnum.UNIVERSITY:
        return universityConf;
      case CompanyEnum.STRUCTURALIA:
        return structuraliaConf;
      case CompanyEnum.UDAVINCI:
        return udavinciConf;
      case CompanyEnum.CEUPE:
        return ceupeConf;
      default:
        return euroinnovaConf;
    }
  }
}
