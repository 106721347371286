import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService } from '@abacritt/angularx-social-login';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Deserialize, IJsonObject, Serialize } from 'dcerialize';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { CourseParametersFilter } from '../../../models/course';
import { LoginResponse } from '../../../models/loginResponse';
import { RegisterOAuth, User, UserDataUpdate } from '../../../models/user';
import { CustomAppleLoginProvider } from '../../../providers/apple-login-provider';
import { EuroinnovaLoginProvider } from '../../../providers/euroinnova-login-provider';
import { LinkedinLoginProvider } from '../../../providers/linkedin-login-provider';
import { ApiService } from '../../../services/api.service';
import { CustomCookieService } from '../../../services/custom-cookie.service';
import { DomainConfigService } from '../../../services/domain-config.service';
import { UserService } from '../../../services/user.service';
import { LoginMethod } from '../../../utils/enums';
import { getStorageObject, removeStorageObject, setStorageObject } from '../../../utils/storage-manager';

/**
 * Interface that represents the user credentials
 */
export interface CredentialsInterface {
  email: string;
  password: string;
  remember?: boolean;
}

const loginProviderEuroinnovaConfig = {
  apiPath: 'user/login-euroinnova',
  loginMethod: LoginMethod.Euroinnova
};

const loginProvidersConfig = {
  [FacebookLoginProvider.PROVIDER_ID]: {
    apiPath: 'user/login-facebook',
    loginMethod: LoginMethod.Facebook
  },
  [GoogleLoginProvider.PROVIDER_ID]: {
    apiPath: 'user/login-google',
    loginMethod: LoginMethod.Google
  },
  [LinkedinLoginProvider.PROVIDER_ID]: {
    apiPath: 'user/login-linkedin',
    loginMethod: LoginMethod.LinkedIn
  },
  [EuroinnovaLoginProvider.PROVIDER_ID]: {
    apiPath: 'user/login-euroinnova',
    loginMethod: LoginMethod.Euroinnova
  },
  [CustomAppleLoginProvider.PROVIDER_ID]: {
    apiPath: 'user/login-apple',
    loginMethod: LoginMethod.Apple
  }
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private http = inject(HttpClient);
  private apiService = inject(ApiService);
  private userService = inject(UserService);
  private domainConfigService = inject(DomainConfigService);
  private translateService = inject(TranslateService);
  private customCookieService = inject(CustomCookieService);
  private socialAuthService = inject(SocialAuthService);

  /**
   * The Observable signaling that the user has logged out
   */
  userLoggedOut: Subject<boolean> = new Subject<boolean>();

  /**
   * Url to redirect after login
   */
  redirectUrl: string | null = null;

  emailPassLogin(loginCredentials: CredentialsInterface): Observable<any> {
    return this.http.post<IJsonObject>(`${this.apiService.getApiUrl()}/auth/login`, loginCredentials).pipe(
      map((response) => {
        const data: LoginResponse = Deserialize(response, () => LoginResponse);
        if (data.loginOk && data.accessToken) {
          setStorageObject(environment.accessTokenStorage, data.accessToken);
          this.userService.checkEBookForSync();

          return this.fillUserData().pipe(
            map(() => {
              return of(data.accessToken as string);
            })
          );
        } else if (data.blocked === true) {
          return data.blocked.toString();
        } else {
          return '';
        }
      }),
      catchError((): any => {
        return '';
      })
    );
  }

  login(userData: RegisterOAuth, provider?: string): Observable<any> {
    if (provider) {
      setStorageObject(environment.loginMethodStorage, loginProvidersConfig[provider].loginMethod);

      return this.loginUserOAuth(userData, loginProvidersConfig[provider].apiPath);
    } else {
      setStorageObject(environment.loginMethodStorage, loginProviderEuroinnovaConfig.loginMethod);

      return this.loginUserOAuth(userData, loginProviderEuroinnovaConfig.apiPath);
    }
  }

  /**
   * This method stores the accessToken retrieved from the backend in the local storage
   *
   * @param userData An object with the OAuth request data
   * @param endpoint API path for OAuth provider
   *
   * @returns The data object with the accessToken or an error
   */
  loginUserOAuth(userData: RegisterOAuth, endpoint: string): Observable<any> {
    return this.http
      .post<IJsonObject>(
        this.apiService.getApiUrl() + '/' + endpoint,
        Serialize(userData, () => RegisterOAuth)
      )
      .pipe(
        switchMap((response) => {
          const data: LoginResponse = Deserialize(response, () => LoginResponse);
          if (data.loginOk && data.accessToken && data.refreshToken) {
            this.userService.checkEBookForSync();
            setStorageObject(environment.accessTokenStorage, data.accessToken);
            this.customCookieService.createUserSessionCookie(data.accessToken, this.apiService.getSubdomain());
            setStorageObject(environment.accessRefreshTokenStorage, data.refreshToken);
            setStorageObject(environment.currentUserLanguageStorage, userData.language);

            return this.fillUserData().pipe(
              map(() => {
                return of(data.accessToken as string);
              })
            );
          } else if (data.blocked === true) {
            return of(data.blocked.toString());
          } else {
            return of('');
          }
        }),
        catchError((): any => {
          return of('');
        })
      );
  }

  /**
   * Whether the user has a valid token or not
   */
  loggedIn(): boolean {
    return !!getStorageObject(environment.accessTokenStorage);
  }

  /**
   * Remove the token from local storage and redirect the user to login page
   */
  logout(): void {
    removeStorageObject(environment.accessTokenStorage);
    this.customCookieService.deleteUserSessionCookie();
    removeStorageObject(environment.accessRefreshTokenStorage);
    removeStorageObject(environment.userDataStorage);
    removeStorageObject(environment.userEnrolmentStorage);
    removeStorageObject(environment.loginMethodStorage);
    removeStorageObject('courseData');
    this.socialAuthService.signOut().then(() => {
      this.userLoggedOut.next(true);
    });
  }

  /**
   * Method that retrieves the user data from the JWT
   *
   * @return Object User data
   */
  getUserData(): User {
    return getStorageObject(environment.userDataStorage);
  }

  getSupportMode(): boolean {
    return getStorageObject(environment.supportModeStorage);
  }

  /**
   * Method to clean support mode and user data from local storage
   */
  cleanSupportMode(): void {
    // Remove support mode and user data from local storage
    if (this.getSupportMode()) {
      removeStorageObject(environment.supportModeStorage);
      this.logout();
    }
  }

  /**
   * Method to fill localStorage with user required data
   */
  fillUserData(support: boolean = false): Observable<boolean> {
    const dataLoaded = new Subject<boolean>();
    forkJoin([
      this.userService.getCoursesRegistered(new CourseParametersFilter(undefined, undefined, -1), true),
      this.userService.profile(),
      this.userService.getSavedCoursesIds(true),
      this.userService.getSavedArticles(),
      this.userService.getEnroledWebinars()
    ]).subscribe((data) => {
      const userData = data[1];

      const currentUserLanguage = getStorageObject(environment.currentUserLanguageStorage);
      if (currentUserLanguage && currentUserLanguage !== userData.language && !support) {
        const userDataUpdate = new UserDataUpdate();
        userDataUpdate.language = currentUserLanguage;

        this.userService.updateCurrentUser(userDataUpdate).subscribe((userInfo) => {
          this.userService.updateUserStorage(userInfo);
          dataLoaded.next(true);
        });
      } else {
        if (userData.language) {
          this.translateService.use(userData.language as string);
          setStorageObject(environment.currentUserLanguageStorage, userData.language);
        }

        this.userService.updateUserStorage(userData);
        dataLoaded.next(true);
      }
    });

    return dataLoaded.asObservable();
  }

  /**
   * Check if the current domain is valid for accessing the Hub
   *
   * @returns {boolean}
   */
  public isValidHubDomain(): boolean {
    const domain = this.apiService.getDomain();
    const config = this.domainConfigService.getConfigForSubdomain(domain);

    return config.canEnterHub;
  }
}
